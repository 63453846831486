<template>
  <div>
    <van-nav-bar title="报修页面" fixed border/>

    <div class="nav-item-group page-container">
      <div class="nav-item nav-item-create-workorder" @click="onCreateWorkorderClick">
        <img src="@/asset/image/repair.jpg" alt="发起报修 ICON">
        <div class="item-card">
          <span style="font-size: 34px">发起报修</span>
          <span class="english">Request repair service</span>
        </div>
      </div>

      <div class="nav-item nav-item-query-workorder" @click="onQueryWorkordersClick">
        <img src="@/asset/image/record.jpg" alt="查看报修进度 ICON">
        <div class="item-card">
          <span style="font-size: 34px">查看报修进度</span>
          <span class="english">Repair progress</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();

onBeforeMount(validationUrlParam);
const onCreateWorkorderClick = () => {
  router.push({query: getUrlParam(), path: "/workorder/prepare"});

};
const onQueryWorkordersClick = () => {
  router.push({query: getUrlParam(), path: "/query-workorder"});
  // Dialog.alert({
  //   message: "尊敬的用户您好！本功能正在维护中，预计3月25日开放。",
  //   confirmButtonText: "好的"
  // }).then(() => {
  //   // on close
  // });
};
</script>

<style scoped>
.nav-item-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(240, 240, 240);

}

.nav-item {
  width: 96%;
  padding: 30px 0;
  letter-spacing: 8px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
}

.nav-item img {
  width: 250px;
  display: inline-block;
  vertical-align: middle;
}

.nav-item-create-workorder {
  color: #009C86;
  letter-spacing: 1px;
}

.nav-item-query-workorder {
  color: #BA68C8;
  letter-spacing: 1px;
}

.english {
  width: 100%;
  margin-left: 3px;
  margin-top: 3px;
  display: inline-block;
  font-size: 12px;
  color: #ccc;
  letter-spacing: 0
}

.item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px
}
</style>
